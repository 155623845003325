.company-details-main {
  background-color: #272829;
  padding: 10px;
  margin-top: 30px;
  flex-direction: column;
  color: rgb(195, 201, 212);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cs-main-logo {
  fill: rgb(195, 201, 212);
  width: 200px;
  height: 25px;
}
.cs-main-layout {
  background: #eaf0f9;
  display: flex;
  min-height: 100vh;
  flex-direction: row;
}
.cs-layout-sidebar {
  width: 17% !important;
  height: 100vh;
  z-index: 2;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #272829;
}
.cs-layout-children {
  padding-left: 5px;
  padding-right: 5px;
  min-height: 100vh;
  height: 100%;
  flex-grow: 1;
}
.cs-layout-sidebar-desktop {
  margin-top: 20px;
}
.cs-layout-menu {
  width: 100%;
}
.cs-main-layout-drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cs-layout-button {
  cursor: pointer;
  border: none;
  background: none;
  padding-top: 15px;
  padding-bottom: 15px;
  color: rgb(195, 201, 212);
  width: 100%;
}
.cs-layout-button:hover {
  background: rgb(90, 94, 102);
}
.cs-layout-button-active {
  background: rgb(90, 94, 102);
}
.cs-layout-menu-text {
  padding-left: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}
.dashboard-layout-sidemenu-icon {
  width: 25px !important;
  fill: rgb(195, 201, 212) !important;
  height: 25px !important;
}
.dashboard-layout-sidemenu-name {
  margin-left: 20px;
}
.cs-logout-button {
  margin-top: 30px;
  color: rgb(195, 201, 212);
}
.company-profile-details {
  font-size: 13px;
}
.cs-common-title-bar {
  margin-top: 5px;
  background-color: #dae5f6;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-common-filter-bar {
  background-color: #acb7d8;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-common-product-bar {
  margin-top: 5px;
  background-color: #dae5f6;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-title-main-font {
  font-family: "Poppins";
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
}
.cs-main-title-add {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cs-title-main-add-button {
  width: 30px !important;
  height: 30px !important;
  padding: 5px;
  cursor: pointer;
}
.cs-title-main-add-button:hover {
  fill: rgb(0, 34, 255) !important;
}
.cs-main-children-data {
  margin-top: 10px;
  border-radius: 10px;
  background-color: white;
  max-height: calc(100vh - 215px);
  /* max-width: calc(100vw - 360px); */
  overflow-y: auto;
}
.noImage {
  height: 80%;
  width: 40%;
}
.nodatafound-space {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: calc(100vh - 215px);
}
.input-grey-rounded {
  width: 150px;
  height: 20px;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-size: 14px;
  border: none;
  padding: 5px 25px 5px 15px;
}
.input-grey-rounded:focus-visible {
  border: none;
  outline: none;
}
.select-flter {
  height: 35px;
  width: 130px;
  background-color: #fff;
  border: 2px solid black;
  border-radius: 20px;
  padding: 5px 5px 5px 5px;
}
.select-flter-option {
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  text-align: center;
  border: 1px solid rgb(75, 0, 151);
  color: white;
  background-color: rgb(75, 0, 151);
  padding: 15px 20px !important;
  font-weight: 700 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
}
.clear-filter-option {
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: red;
}
.select-flter-option:hover {
  background-color: rgb(0, 0, 0);
  color: white;
  animation: 1s;
}
.filter-top-space {
  margin-top: 5px;
}
.filter-options {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
}
.cs-main-button {
  padding: 8px 15px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(75, 0, 151);
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: rgb(75, 0, 151);
  border-radius: 20px;
  cursor: pointer;
}
.cs-main-filter-button {
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(75, 0, 151);
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: rgb(75, 0, 151);
  border-radius: 20px;
  cursor: pointer;
}
.cs-main-filter-button:hover {
  background-color: black;
  border: 1px solid black;
  color: white;
}
.cs-main-active-button {
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
  color: white;
}
.cs-main-button:hover {
  background-color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
}
.lottie-icon-button {
  margin-right: 10px;
}
.filter-main-dialog .MuiDialog-paper {
  border-radius: 20px !important;
}
.addDialog-title {
  font-size: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  color: rgb(75, 0, 151);
}
.close-dialog-button {
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  color: rgb(75, 0, 151);
  border: none;
  background: none;
}
.close-dialog-button:hover {
  color: black;
  font-size: 16px;
  animation: fadeIn 0.5s;
}
.button-group-dialog {
  display: flex;
  align-items: center;
}
.addDialog-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loader-mainlottie {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 500px) {
  .cs-main-layout {
    flex-direction: column;
  }
  .cs-layout-sidebar {
    width: 100% !important;
    height: auto;
  }
  .company-details-main {
    margin-top: 0;
  }
  .cs-layout-sidebar-desktop {
    display: none;
  }
  .cs-layout-sidebar-mobile {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    z-index: 2;
    align-items: center;
  }
  .cs-layout-button {
    padding: 20px 20px 20px 0px;
  }
  .cs-layout-menu-text {
    padding-left: 10px;
    white-space: nowrap;
  }
  .cs-logout-button {
    margin-top: 0;
  }
  .dashboard-layout-sidemenu-name {
    margin-left: 5px;
  }
  .noImage {
    height: 80%;
    width: 100%;
  }
  .select-flter {
    width: 120px;
  }
  .cs-main-children-data {
    max-height: 100vh;
  }
  .add-scroll {
    overflow-x: scroll;
  }
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .cs-main-layout {
    flex-direction: column;
  }
  .cs-layout-sidebar {
    width: 100% !important;
    height: auto;
  }
  .cs-main-logo {
    width: 200px;
    height: 25px;
  }
  .company-details-main {
    margin-top: 0;
  }
  .cs-layout-sidebar-desktop {
    display: none;
  }
  .cs-layout-sidebar-mobile {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    align-items: center;
  }
  .cs-layout-button {
    padding: 20px 20px 20px 0px;
  }
  .cs-layout-menu-text {
    padding-left: 10px;
    white-space: nowrap;
  }
  .cs-logout-button {
    margin-top: 0;
  }
  .dashboard-layout-sidemenu-name {
    margin-left: 5px;
  }
  .noImage {
    height: 80%;
    width: 60%;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .cs-main-layout {
    flex-direction: column;
  }
  .cs-layout-sidebar {
    width: 100% !important;
    height: auto;
  }
  .company-details-main {
    margin-top: 0;
  }
  .cs-layout-sidebar-desktop {
    display: none;
  }
  .cs-layout-sidebar-mobile {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    align-items: center;
  }
  .cs-layout-button {
    padding: 20px 20px 20px 0px;
  }
  .cs-layout-menu-text {
    padding-left: 10px;
    white-space: nowrap;
  }
  .cs-logout-button {
    margin-top: 0;
  }
  .dashboard-layout-sidemenu-name {
    margin-left: 5px;
  }
  .cs-layout-children {
    padding-left: 15px;
    padding-right: 15px;
  }
  .noImage {
    height: 80%;
    width: 60%;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1350px) {
  .cs-layout-sidebar-mobile {
    display: none;
  }
  .cs-layout-sidebar {
    width: 17% !important;
  }
  .company-details-main {
    margin-top: 10px;
  }
  .cs-main-logo {
    width: 150px;
    height: 25px;
  }
  .company-profile-details {
    font-size: 10px;
  }
  .cs-layout-sidebar-desktop {
    margin-top: 0;
  }
  .cs-layout-button {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .cs-layout-menu-text {
    font-size: 13px;
    padding-left: 0;
    line-height: 16px;
  }
  .dashboard-layout-sidemenu-icon {
    width: 20px !important;
    height: 20px !important;
  }
  .dashboard-layout-sidemenu-name {
    margin-left: 8px;
  }
  .cs-layout-children {
    padding-left: 40px;
    padding-right: 40px;
  }
  .cs-main-button {
    font-size: 12px;
    line-height: 14px;
  }
  .cs-main-filter-button {
    font-size: 12px;
    line-height: 14px;
  }
  .cs-title-main-font {
    font-size: 16px;
    line-height: 18px;
  }
}
@media only screen and (min-width: 1350px) and (max-width: 1650px) {
  .cs-layout-sidebar-mobile {
    display: none;
  }
  .cs-layout-sidebar {
    width: 15% !important;
  }
  .company-details-main {
    margin-top: 10px;
  }
  .cs-main-logo {
    width: 200px;
    height: 25px;
  }
  .company-profile-details {
    font-size: 12px;
  }
  .cs-layout-sidebar-desktop {
    margin-top: 0;
  }
  .cs-layout-button {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .cs-layout-menu-text {
    font-size: 13px;
    padding-left: 10px;
    line-height: 15px;
  }
  .dashboard-layout-sidemenu-icon {
    width: 25px !important;
    height: 25px !important;
  }
  .dashboard-layout-sidemenu-name {
    margin-left: 10px;
  }
  .cs-layout-children {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (min-width: 1651px) {
  .cs-layout-sidebar-mobile {
    display: none;
  }
  .cs-layout-children {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 1025px) {
  .cs-main-layout {
    height: 100%;
  }
  .cs-layout-sidebar {
    height: 100%;
    position: fixed;
    min-height: 100vh !important;
  }
  .cs-layout-children {
    height: 100%;
  }
}
