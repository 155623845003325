body {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
}

.mesh-loader {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  z-index: 99999999999;
}

.mesh-loader .circle {
  width: 25px;
  height: 25px;
  position: absolute;
  background: #2962ff;
  border-radius: 50%;
  margin: -12.5px;
  -webkit-animation: mesh 3s ease-in-out infinite;
  animation: mesh 3s ease-in-out infinite -1.5s;
}

.mesh-loader > div .circle:last-child {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.mesh-loader > div {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999999999;
}

.mesh-loader > div:last-child {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

@-webkit-keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  50.00001% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  50.00001% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
