.login-background {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: fixed;
}

.login-steps {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: 40px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.login-card {
  padding: 0px 40px 30px 40px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  margin-top: 70px;
  margin-bottom: 100px;
}

.login-first-image {
  margin-left: -203px;
  margin-top: -98px;
  margin-bottom: -20px;
}

.login-second-image {
  margin-top: -70px;
  position: absolute;
  right: -60px;
}

.login-logo {
  text-align: center;
  position: relative;
  top: -105px;
  margin-top: -50px;
}

.login-input-div {
  position: relative;
  border: 2px solid #d2d2d2;
  border-radius: 10px;
  padding: 2px 5px 2px 30px;
  margin-bottom: 22px;
  overflow: hidden;
}

.login-input-icon {
  position: absolute;
  top: 20px;
  height: 28px;
}
.login-input-icon2 {
  position: absolute;
  right: 35px;
  top: 23px;
  height: 17px;
  background-color: white;
  border-radius: 30px;
}

.login-input-div input {
  height: 60px;
  width: 88%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: 0;
  margin-left: 50px;
  outline: none;
  color: #9f9f9f;
  letter-spacing: 0.2px;
  background: none;
}

.login-input-div input::-webkit-input-placeholder {
  color: #9f9f9f;
}

.login-input-div input:-ms-input-placeholder {
  color: #9f9f9f;
}

.login-input-div input::placeholder {
  color: #9f9f9f;
}

.continue-signin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-top: -60px;
  margin-left: 32px;
}

.login-forgotpassword {
  float: right;
  color: black;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  margin-top: 0px;
  text-decoration: none;
  letter-spacing: 0.5px;
}

.sign-in-btn {
  height: 65px;
  width: 100%;
  border-radius: 10px;
  background-color: black;
  color: white;
  margin-top: 5px;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.5px;
  outline: none;
  border: 0;
}

.login-with-google {
  height: 65px;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  color: black;
  margin-top: 22px;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  letter-spacing: 0.5px;
  outline: none;
  border: 2px solid #d2d2d2;
}

.login-google-icon {
  height: 30px;
}

.login-with-google span {
  position: relative;
  top: -8px;
  left: 5px;
}

.login-signup-text {
  text-align: center;
  color: #696969;
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  margin-top: 20px;
  letter-spacing: 0.5px;
  position: relative;
  z-index: 99;
}

.login-signup-text-link {
  color: black;
  text-decoration: none;
}

/* LOGIn */

.main-login-page {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.login-section {
  width: 41%;
  display: flex;
  justify-content: center;
  margin: 135px 0;
}
.signup-section {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: 30px 0;
}
.login-div {
  position: relative;
  width: 70%;
  background-color: white;
  padding: 30px 40px;
}
.login-vector-up {
  position: absolute;
  top: -60px;
  left: -115px;
  width: 180px;
  height: 180px;
}
.login-vector-down {
  position: absolute;
  bottom: -110px;
  right: -50px;
  width: 180px;
  height: 180px;
}
.login-logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-logo-image {
  width: 190px;
  height: 45px;
}
.title-login {
  margin-top: 20px !important;
  margin-left: 15px !important;
}
.login-main-form {
  margin-top: 25px;
}
.loginform-field-icons {
  width: 25px;
  height: 25px;
  fill: rgb(52, 52, 52);
}
.main-login-form .MuiOutlinedInput-notchedOutline {
  border: 2px solid #d2d2d2 !important;
  border-radius: 14px !important;
}
.main-login-form .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #9f9f9f !important;
  border-radius: 14px !important;
}
.main-login-form .MuiInputLabel-outlined {
  color: rgb(52, 52, 52) !important;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
}
.main-login-form .MuiOutlinedInput-input {
  color: rgb(52, 52, 52) !important;
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 20px 5px !important;
}
.main-login-form .MuiOutlinedInput-input::placeholder {
  color: rgb(52, 52, 52) !important;
}
.main-login-form {
  font-weight: 600 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
}
.login-space-divider {
  margin-top: 25px;
}
.forgot-pass-div-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.forgot-pass-login {
  text-align: end;
  cursor: pointer;
}
.purple-input-field .MuiOutlinedInput-notchedOutline {
  border: 2px solid #272727 !important;
  border-radius: 14px !important;
}
.purple-input-field .MuiOutlinedInput-notchedOutline legend span {
  display: none;
}
.purple-input-field .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #272727 !important;
  border-radius: 14px !important;
}
.purple-input-field .MuiInputLabel-outlined {
  color: #272727 !important;
  background-color: #f4f6fc;
  padding: 0px 4px;
  font-weight: 600 !important;
}
.purple-input-field .MuiOutlinedInput-input {
  color: #272727 !important;
  font-weight: 600 !important;
}
.purple-input-field .MuiOutlinedInput-input::placeholder {
  color: #272727 !important;
}
.forgot-pass-login:hover {
  animation: 0.5s fade-in;
}
.signin-button-main {
  margin-top: 15px;
}
.signin-button-login-page {
  padding: 20px;
  width: 100%;
  background: #000000;
  border: 1px solid black;
  border-radius: 14px;
  color: #ffffff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.signin-button-login-page:hover {
  background: #d6d6d6;
  border: 1px solid black;
  color: black;
}
.signin-button-login-page:focus {
  background: #000000;
  border: 1px solid black;
  color: #ffffff;
}
.google-login-button {
  padding: 20px;
  width: 100%;
  background: white;
  border: 1px solid #b3b3b3;
  border-radius: 14px;
  font-weight: 600;
  color: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.google-login-button:hover {
  background: #d6d6d6;
  border: 2px solid #b3b3b3;
  color: black;
}
.google-login-fonts {
  margin-left: 10px;
}
.signup-text-login {
  margin-top: 20px;
  font-weight: 600;
  color: #696969;
  text-align: center;
}
.signuptext {
  color: #212121;
  cursor: pointer;
  font-weight: 900;
  text-decoration: none;
}
.text-decoration-signup {
  text-decoration: underline;
}
.signuptext:hover {
  color: red;
  animation: 0.5s fade-in;
}
.randomtext-signup-page {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
}
.signup-random-text {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  background: #7c7c7c;
  border-radius: 14px;
  font-weight: 900;
  color: #e3e3e3;
  width: auto;
}
.random-signup-code {
  width: 41%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.botton-space-divider {
  margin-bottom: 20px;
}
.signup-text-icon {
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
.refresh-signup-icon {
  width: 30px;
  height: 30px;
}
.signup-random-content {
  width: auto;
  border: 2px solid #d2d2d2;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.random-input-signup {
  border: none;
  font-weight: 600;
  margin-left: 15px;
  width: 70%;
  color: rgb(52, 52, 52);
}
.random-input-signup::-webkit-input-placeholder {
  color: rgb(114, 114, 114);
}

.random-input-signup:-ms-input-placeholder {
  color: rgb(114, 114, 114);
}

.random-input-signup::placeholder {
  color: rgb(114, 114, 114);
}
.random-input-signup:focus {
  outline: 0 !important;
}
div#menu-country .MuiMenu-paper {
  max-height: 500px !important;
}
div#menu-state .MuiMenu-paper {
  max-height: 500px !important;
}
div#menu-jobDescription .MuiMenu-paper {
  max-height: 500px !important;
}
.mobile-field-signup input::-webkit-outer-spin-button,
.mobile-field-signup input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mobile-field-signup input[type="number"] {
  -moz-appearance: textfield;
}

/* Login */
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .login-section {
    width: 100%;
    margin: 70px 0;
  }
  .signup-section {
    width: 100%;
    margin: 20px 0;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .google-login-button {
    padding: 15px;
  }
  .login-vector-down {
    display: none;
    /* bottom: -75px;
      right: 0;
      width: 90px;
      height: 90px; */
  }
  .randomtext-signup-page {
    gap: 5px;
  }
  .login-vector-up {
    width: 150px;
    height: 150px;
    top: -56px;
    left: -84px;
  }
  .login-logo-image {
    width: 150px;
    height: 35px;
  }
  .title-login {
    margin-top: 10px !important;
  }
  .main-login-form .MuiOutlinedInput-input {
    font-size: 14px !important;
    line-height: 16px !important;
    padding: 15px 5px !important;
  }
  .loginform-field-icons {
    width: 20px;
    height: 20px;
  }
  .refresh-signup-icon {
    width: 25px;
    height: 25px;
  }
  .random-signup-code {
    width: 50%;
  }
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .login-section {
    width: 90%;
    margin: 100px 0;
  }
  .signup-section {
    width: 100%;
    margin: 20px 0;
  }
  .google-login-button {
    padding: 15px;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .login-vector-up {
    width: 150px;
    height: 150px;
    top: -56px;
    left: -84px;
  }
  .login-vector-down {
    bottom: -40px;
    right: 0;
    width: 90px;
    height: 90px;
  }
  .login-logo-image {
    width: 150px;
    height: 35px;
  }
  .title-login {
    margin-top: 10px !important;
  }
  .main-login-form .MuiOutlinedInput-input {
    font-size: 14px !important;
    line-height: 16px !important;
    padding: 15px 5px !important;
  }
  .loginform-field-icons {
    width: 20px;
    height: 20px;
  }
  .refresh-signup-icon {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .login-section {
    width: 70%;
    margin: 135px 0;
  }
  .signup-section {
    width: 70%;
    margin: 20px 0;
  }
  .login-logo-image {
    width: 170px;
    height: 40px;
  }
  .login-vector-up {
    width: 170px;
    height: 170px;
    top: -56px;
    left: -84px;
  }
  .title-login {
    margin-top: 15px !important;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .login-vector-down {
    bottom: -70px;
    right: 0;
    width: 90px;
    height: 90px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .login-section {
    width: 60%;
    margin: 135px 0;
  }
  .signup-section {
    width: 60%;
    margin: 20px 0;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .title-login {
    margin-top: 15px !important;
  }
  .login-logo-image {
    width: 180px;
    height: 40px;
  }
  .login-vector-up {
    width: 170px;
    height: 170px;
    top: -50px;
    left: -84px;
  }
  .login-vector-down {
    bottom: -60px;
    right: 0;
    width: 120px;
    height: 120px;
  }
}

@media only screen and (min-width: 1256px) and (max-width: 1439px) {
  .login-section {
    width: 55%;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .title-login {
    margin-top: 15px !important;
  }
  .login-logo-image {
    width: 190px;
    height: 45px;
  }
  .login-vector-up {
    width: 170px;
    height: 170px;
    top: -50px;
    left: -84px;
  }
  .login-vector-down {
    bottom: -60px;
    right: 0;
    width: 120px;
    height: 120px;
  }
}
